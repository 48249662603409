/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sidebar-item {
    @apply flex py-2 px-3 rounded-md hover:bg-hoverNav w-full my-2;
  }

  .sidebar-item.active {
    color: #3498db;
  }
  .table-columns {
    max-height: 120px;
    @apply min-h-table-cell-single h-max flex relative hover:bg-tableHover;
  }
  .table-columns-inner {
    @apply min-h-table-cell-single h-max flex relative;
  }
  .table-cell-column {
    @apply flex items-center justify-between px-4 min-h-table-cell-single font-bold;
  }
  .table-cell-row {
    @apply flex items-center px-2 flex-wrap py-1 w-full min-h-[35px];
  }
  .popup-button-container {
    display: none;
  }
  .table-cell-row:hover .popup-button-container {
    display: block;
  }
  .form-columns {
    @apply min-h-table-cell-single h-max flex relative w-full justify-between bg-tableBG hover:bg-tableHover ;
  }
  .table-cell-form {
    @apply border flex items-center px-4 flex-wrap py-1 ;
  }  
  .table-inner-cell {
    @apply flex items-center flex-wrap ;
  }
  .row-buttons {
    @apply hidden;
  }
  .table-column-key:hover .row-buttons {
    display: block;
  }
  .table-column-key:hover .row-button-key {
    @apply hidden;
  }
  .table-inner-cell {
    min-height: 20px;
    min-width: 20px;
  }
  .table-inner-cell:hover input,
  .table-inner-cell input:focus {
    border: 2px solid #73BBF6;
    padding: 2px 0;
    background-color: white;
  }
  .table-cell-form input:focus {
    border: 2px solid #73BBF6;
    outline: #73BBF6;
  }
  .form-error-message {
    @apply absolute top-[50px] left-[30px] text-white bg-red-600 rounded px-4
  }
  .btn {
    @apply text-white rounded-md mt-3 py-1 px-5 bg-primary
  }
  .table-cell-form:focus-within { 
    background-color: #D7ECFD;
  }
  .table-cell-form input {
    padding: 2px 5px;
  }
  .footer-form {
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-color: rgb(163 163 163);
  }
}

.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.white-spinner {
  border-top: 2px solid #fff;
  border-radius: 80%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.small-spinner {
  width: 20px;
  height: 20px;
  border-width: 5px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 